<template>
  <div id="studyProduct">
    <!-- <div class="allTop" ref="allTop"> -->
    <div class="index-search" ref="search">
      <div class="search-input" @click="SearchShow = true">
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22.04 11.5C22.04 17.2973 17.3317 22 11.52 22C5.70833 22 1 17.2973 1 11.5C1 5.70265 5.70833 1 11.52 1C17.3317 1 22.04 5.70265 22.04 11.5Z"
            stroke="#ff4848"
            stroke-width="2"
          />
          <path
            d="M19.8193 18.7751L24 22.9485"
            stroke="#ff4848"
            stroke-width="2"
            stroke-linecap="round"
          />
        </svg>
        <p>请输入商品名称</p>
        <div class="btn">搜索</div>
      </div>
    </div>

    <!-- <div class="bigClass" ref="bigClass">
        <div
          class="bigClass_li "
          :class="bigClass == 1 ? 'act' : ''"
          @click="changeBigClass(1)"
        >
          <img
            v-if="bigClass == 1"
            src="https://image.shop.19jiang.cn/home/store/goods/2/alioss_2_2022050616432771741.png"
          />
          <img
            v-if="bigClass == 2"
            src="https://image.shop.19jiang.cn/home/store/goods/2/alioss_2_2022050616432770722.png"
          />
          商品
        </div>
        <div
          class="bigClass_li"
          :class="bigClass == 2 ? 'act' : ''"
          @click="changeBigClass(2)"
        >
          <img
            src="https://image.shop.19jiang.cn/home/store/goods/2/alioss_2_2022050616432774253.png"
            alt=""
            v-if="bigClass == 1"
          />
          <img
            src="https://image.shop.19jiang.cn/home/store/goods/2/alioss_2_2022050616432719245.png"
            v-if="bigClass == 2"
          />
          课程
        </div>
      </div> -->
    <!-- </div> -->

    <div
      class="allP_left"
      ref="left"
      :style="{
        height: leftHeight + 'px',
      }"
    >
      <swiper
        ref="Left_Swiper"
        :options="leftTitle"
        class="swiper-container-left"
        style="z-index: 997"
      >
        <swiper-slide ref="left_Pos_Wrap">
          <p
            @click="toHref(i, item.id, item.value)"
            ref="left_Pos"
            v-for="(item, i) in listData"
            :key="i"
          >
            <b v-bind:class="{ act: i == index }">{{ item.value }}</b>
            <span class="solid" v-if="i == index"></span>
          </p>
        </swiper-slide>
      </swiper>
    </div>

    <div class="all-right" :style="{ height: leftHeight + 'px' }">
      <swiper
        :auto-update="true"
        :auto-destroy="true"
        :delete-instance-on-destroy="true"
        :cleanup-styles-on-destroy="true"
        ref="Right_Swiper"
        :options="rightTitle"
        class="swiper-container-right"
        style="z-index: 999999999"
      >
        <swiper-slide ref="left_Pos_Wrap" id="right_product_wrap">
          <div class="product">
            <!-- <div class="product" :style="{ height: leftHeight + 'px' }"> -->
            <ul v-if="productData && productData.length != 0">
              <li
                @click="goRouter(item)"
                class="product-list"
                v-for="(item, pIndex) in productData"
                v-if="!item.state"
                :key="pIndex"
              >
                <div class="product-img">
                  <img
                    class="product-img"
                    :src="
                      `${item.goodsimage_full_url ||
                        item.cover_img ||
                        item.img_url_compressed}?x-oss-process=image/resize,w_200,h_200`
                    "
                  />
                </div>
                <div class="info">
                  <div class="h1">
                    <h1>{{ item.goods_name || item.title }}</h1>
                  </div>
                  <p v-if="item.open">剩余名额：{{ item.open }}</p>
                  <p v-else-if="item.join_count">售出{{ item.join_count }}</p>
                  <p v-else></p>
                  <div class="price">
                    <template>
                      <!-- <b v-if="index == 1"
                        >￥{{ regFenToYuan(item.price_low) }}</b
                      > -->

                      <b
                        >￥{{
                          item.promotion_price
                            ? parseFloat(item.goods_promotion_price) ||
                              parseFloat(item.promotion_price)
                            : parseFloat(item.goods_price)
                        }}</b
                      >
                    </template>
                    <span v-if="item.goods_marketprice"
                      >￥{{ parseFloat(item.goods_marketprice) }}</span
                    >
                  </div>
                </div>
              </li>
              <li style="height:200px;" id="loading">
                {{ !loading && !sock ? "上拉加载更多" : "" }}
                {{ loading && !sock ? "加载中" : "" }}
                {{ loading && sock ? "--无更多数据--" : "" }}
              </li>
            </ul>
            <van-empty image="search" :description="tips" v-else />
          </div>
        </swiper-slide>

        <div class="swiper-scrollbar" slot="scrollbar"></div>
      </swiper>
    </div>
    <oSearch :boo.sync="SearchShow"></oSearch>
    <oSku
      :boo.sync="skuBoo"
      :id="goods_id"
      @flush="fulshCart"
      :cartGoods="cartGoods"
    ></oSku>
  </div>
</template>

<script>
import merge from "webpack-merge";
import { getClassList } from "@/view/home/view/index/components/study/js";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import { post } from "@get/http";
import oSearch from "@view/home/view/goodsSearch/setup.vue";
import oSku from "@view/h5/offlineMall/componetns/sku/index.vue";
import { Empty, Dialog, Toast } from "vant";
import "swiper/css/swiper.css";
import {
  getCartDel,
  getCartAdd,
  getCartList,
  getCartQuantity,
} from "@view/cart/js/";

export default {
  components: {
    Swiper,
    SwiperSlide,
    vanEmpty: Empty,
    oSearch,
    oSku,
    // HomeCommonSearch,
  },
  swiperLoadingInit: false,
  data() {
    return {
      skuBoo: false, //sku打开
      SearchShow: false, //搜索打开
      leftTitle: {
        direction: "vertical",
        slidesPerView: "auto",
        freeMode: true,
        mousewheel: true,
        resistanceRatio: 0,
      },
      loading: false,
      sock: false,
      bigClass: 1, // 大类
      rightTitle: {
        freeMode: true,
        mousewheel: true,
        resistanceRatio: 0,
        direction: "vertical",
        nested: true,
        slidesPerView: "auto",
        freeMode: true,
        on: {
          touchMove: (swiper) => {
            let that = this.swiper_right;
            this.swiperLoadingInit = true;
            if (-that.translate + that.size + 70 > that.virtualSize) {
              if (!this.sock && !this.loading) {
                this.getGoodsList(this.class_id);
              }
            }
          },
          transitionEnd: (swiper) => {
            if (!this.swiperLoadingInit) return;
            let that = this.swiper_right;
            if (-that.translate + that.size + 70 > that.virtualSize) {
              if (!this.sock && !this.loading) {
                this.getGoodsList(this.class_id);
              }
            }
          },
        },

        // direction: "vertical",
        // slidesPerView: "auto",
        // freeMode: true,
        // mousewheel: true,
        // resistanceRatio: 0,
        // scrollbar: {
        //   draggable: true,
        //   hide: false,
        //   el: ".swiper-scrollbar",
        //   dragSize: 30,
        //   snapOnRelease: false,
        // },
      },

      leftHeight: 0,
      listData: [], //二级分类数据
      index: 0, //当前选中序号
      order_key: "", //价格降升序字段
      sort_id: 5, //排序ID
      initToIndex: -1,

      goods_id: 0, //SKU选中的产品ID
      class_id: "", //分类ID
      productData: [], //列表数据
      tips: "搜索中",
      cartGoods: [], //购物车产品
    };
  },

  props: {
    barheight: {},
  },
  created() {
    this.getGoodsclassList();
    // this.getCart();
  },
  computed: {
    swiper() {
      return this.$refs.Left_Swiper.$swiper;
    },
    swiper_right() {
      return this.$refs.Right_Swiper.$swiper;
    },
  },
  mounted() {
    const h1 = window.screen.height;
    const h2 = document.body.clientHeight;
    let searchHegiht = this.$refs.search.offsetHeight;
    // let bigClassHegiht = this.$refs.bigClass.offsetHeight;
    // console.log(this.$refs.allTop);
    // let allTopHeight = this.$refs.allTop.offsetHeight;
    this.leftHeight = h1 - h2 - searchHegiht;
    // setTimeout(() => {
    //   document.title = "全部产品";
    //   console.log(">>>.");
    // }, 2500);
    // document
    //   .getElementById("studyProduct")
    //   .addEventListener("touchmove", function(e) {
    //     console.log("asd");
    //     e.preventDefault();
    //   });
  },
  page: 1,
  methods: {
    changeBigClass(cur) {
      this.bigClass = cur;
    },
    goRouter(item) {
      // if (this.index == 1) {
      //   window.location.href = `https://appg5q9tdm86610.h5.xiaoeknow.com/v1/goods/goods_detail/${item.id}?type=3`;
      // }
      // if (this.index == 1) {
      //   window.location.href = item.link;
      // } else {
      this.$router.push({
        name: "HomeGoodsdetail",
        query: {
          goods_id: item.goods_id,
        },
      });
      // }
    },
    ShowSku(id, sku_num) {
      this.goods_id = id;
      this.skuBoo = true;
    },

    getCartNum(id, type = "buy_num") {
      // 获得购物车选中值

      for (let index = 0; index < this.cartGoods.length; index++) {
        if (this.cartGoods[index].goods_id == id) {
          if (type == "index") {
            return index;
          }

          return this.cartGoods[index][type];
        }
      }
      return 0;
    },
    async getCart() {
      //获取购物车数据

      let result = await getCartList(this.store_id);

      this.cartGoods = result;
    },
    fulshCart(val) {
      this.getCart();
      this.$store.dispatch("doSetCartNum", "");
      // console.log("刷新购物车");
    },
    subtract(goods_id) {
      let num = this.getCartNum(goods_id);
      let cart_id = this.getCartNum(goods_id, "cart_id");
      //减购物车数量
      num--;
      if (num == 0) {
        Dialog.confirm({
          message: "确定要删除这个产品吗",
        }).then((res) => {
          getCartDel(cart_id).then((res) => {
            this.cartGoods.splice(this.getCartNum(goods_id, "index"), 1);
            Toast({
              message: "删除成功",
              forbidClick: true,
              duration: 1000,
            });
          });
        });
      } else {
        Toast({
          message: "成功",
          forbidClick: true,
          duration: 1000,
        });
        this.setUpdateCart(cart_id, goods_id, num);
      }
    },
    async setUpdateCart(cart_id, goods_id, num) {
      // 更新购物车数量
      let data = await getCartQuantity(cart_id, num);
      this.cartGoods[this.getCartNum(goods_id, "index")].buy_num = data.result;
    },
    sort(id) {
      /***
       * @method sort 排序请求
       * @param {int} id 排序号
       *  5 = 综合
       *  3 = 销量降序
       *  1 = 销量升序
       *  6 = 新品
       *  2 = sort_order = 2 价格升序
       *  2 = sort_order = 1 价格降序
       */
      this.sort_id = id;
      if (id == 2) {
        if (this.order_key == "" || this.order_key == 1) {
          this.order_key = 2;
        } else {
          this.order_key = 1;
        }
      } else {
        this.order_key = "";
      }
      this.dataReset();

      this.getGoodsList(this.class_id, id);
    },
    dataReset() {
      this.page = 1;
      this.sock = false;
      this.loading = false;
      this.productData = [];
    },

    async getGoodsList(id) {
      let dom = this.listData.find((item) => item.id == id);
      if (dom.storegc_state == 0) {
        this.tips = "暂无数据";
        return false;
      }

      this.loading = true; //关闭加载

      let { goods_list } = await post("/lw.Store/store_goods_p_v4", {
        data: {
          page: this.page, //页数
          pageSize: 15,
          storegc_id: this.class_id, // 二级分类ID
          keyword: "",
          store_id: process.env.VUE_APP_STORE, //店铺ID
          sort_order: this?.order_key != "" ? this?.order_key : "",
          sort_key: this.sort_id, //  按什么排序
          //5是默认     价格降序是2  升序 1,2   销量降序是 3  销量升序是 1
        },
      });
      goods_list ??= [];
      if (goods_list.length < 15) {
        this.sock = true;
      } else {
        this.loading = false; // 加载完毕 开启加载
        this.page++;
      }
      this.productData.push(...goods_list);
    },
    async getOnLineList() {},
    async getGoodsclassList() {
      let res = await post("/Store/store_goods_class_v2", {
        data: {
          store_id: process.env.VUE_APP_STORE,
        },
      });
      // 获取店铺分类

      this.listData = res.store_goods_class.filter(
        (item) => item.storegc_state == 1
      );
      this.listData.unshift(
        {
          children: [],
          id: 0,
          value: "全部分类",
        }
        // {
        //   children: [],
        //   id: 1,
        //   value: "线上课堂",
        // },
        // {
        //   children: [],
        //   id: 1,
        //   value: "线下课堂",
        // }
      );

      if (this.$route.query?.list_id != undefined) {
        for (var variable in this.listData) {
          if (this.listData.hasOwnProperty(variable)) {
            if (this.listData[variable].value == this.$route.query.list_id) {
              this.index = Number(variable);
              this.getGoodsDataFoo(this.listData[variable].id);
              // this.getGoodsList();
              break;
            }
          }
        }
      } else {
        this.getGoodsDataFoo(0);
      }
    },

    async toHref(i, id, txt) {
      // 运动
      this.tips = "搜索中";
      this.productData = [];
      if (i != this.index) {
        let dom = this.$refs.left_Pos[i];
        let Total_Middle_Pos = this.leftHeight / 2; //中间的距离
        let el_Height = dom.offsetHeight / 2;
        let doc_Top = dom.offsetTop; //元素距离视口的距离
        // let el_Top              = this.$refs.left_Pos[i].getBoundingClientRect().top            ;      //元素距离视口的距离
        // let el_Top              = this.$refs.left_Pos[i].getBoundingClientRect().top            ;     //元素距离视口的距离
        // let Total_Slid_Pos      = this.$refs.left_Pos_Wrap.$el.offsetHeight - this.height       ;     //总共可滑动的高度距离
        this.index = i;
        this.swiper.translateTo(
          (doc_Top - Total_Middle_Pos + el_Height) * -1,
          200,
          true,
          false
        ); //运动
      }

      // 刷新链接
      this.$router.push({
        query: merge(this.$route.query, {
          list_id: txt,
        }),
      });
      this.class_id = id;
      this.getGoodsDataFoo(id);

      // this.thisListData = id;
    },
    regFenToYuan(fen) {
      var num = fen;
      num = fen * 0.01;
      num += "";
      var reg =
        num.indexOf(".") > -1
          ? /(\d{1,3})(?=(?:\d{3})+\.)/g
          : /(\d{1,3})(?=(?:\d{3})+$)/g;
      num = num.replace(reg, "$1");
      num = this.toDecimal2(num);
      return num;
    },
    toDecimal2(x) {
      var f = parseFloat(x);
      if (isNaN(f)) {
        return false;
      }
      var f = Math.round(x * 100) / 100;
      var s = f.toString();
      var rs = s.indexOf(".");
      if (rs < 0) {
        rs = s.length;
        s += ".";
      }
      while (s.length <= rs + 2) {
        s += "0";
      }
      return s;
    },
    async getGoodsDataFoo(class_id) {
      this.class_id = class_id;

      if (class_id == 1) {
        let data = await getClassList({ page: 1, pageSize: 1000, type: 8 });
        // console.log(data);
        if (data.list && data.list.length != 0) {
          this.productData = data.list;
        } else {
          this.tips = "暂无数据";
        }

        return false;
      }
      // else if (class_id == 1) {
      //   this.sock = true;
      //   this.loading = true;
      //   let { data } = await post(
      //     "/lw.Xiaoet/api",
      //     {
      //       data: {
      //         api_url: "https://api.xiaoe-tech.com/xe.goods.list.get/4.0.0",
      //         method: "POST",
      //         data: {
      //           page: 1,
      //           page_size: 100,
      //           resource_type: 6,
      //           sale_status: 1,
      //           is_return_deleted: 0,
      //           is_return_display: 1,
      //           is_return_password: 0,
      //           is_return_public: 1,
      //           is_forbid: 0,
      //           is_ignore: 0,
      //           is_deleted: 0,
      //           is_display: 1,
      //         },
      //       },
      //     },
      //     { result: true, toast: false }
      //   );
      //   // console.log(data);
      //   if (data.list && data.list.length != 0) {
      //     this.productData = data.list;
      //   } else {
      //     this.tips = "暂无数据";
      //   }
      // }
      else {
        this.dataReset();
        this.getGoodsList(class_id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
#studyProduct {
  background: #fff;
  width: rem(750);
  left: 0;
  right: 0;
  top: 0;
  margin: 0 auto;
  position: fixed;
  // height: 100%;
}
.index-search {
  display: flex;
  padding: rem(16) rem(24);
  // background: #fff;
  background: linear-gradient(180deg, rgba(250, 250, 250, 0) 0%, #fafafa 100%);
  .search-input {
    display: flex;
    width: rem(478);
    flex: 1;
    border: 1px solid #ff4848;

    height: rem(72);
    background: #ffffff;
    border-radius: 36px;
    // background: rgba(246, 246, 246, 0.9);
    align-items: center;
    .btn {
      width: rem(132);
      height: rem(60);
      background: linear-gradient(90deg, #ff6666 0%, #ff4848 100%);
      border-radius: rem(30);
      font-weight: 400;
      font-size: rem(28);
      line-height: rem(39);
      color: #ffffff;
      text-align: center;
      line-height: rem(60);
      margin-right: rem(6);
    }
    svg {
      width: rem(30);
      height: rem(30);
      margin-left: rem(24);
      color: #ff4848;
    }
    p {
      font-size: rem(24);
      margin-left: rem(10);
      color: rgba(0, 0, 0, 0.3);
      flex: 1;
    }
  }
}

.allP_left {
  position: absolute;
  top: rem(104);
  left: 0;

  width: rem(162);
  z-index: 9;
}
.swiper-container-right {
  overflow: hidden;
  width: 100%;
  height: 100%;
  .swiper-slide {
    height: auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
}

// 左边
.swiper-container-left {
  background: #fafafa;

  // margin: 0!important;
  overflow: hidden;
  width: 100%;
  // border-right: 1px solid #ebebeb;
  // height: bai3(816);
  height: 100%;

  .swiper-slide {
    height: auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding-bottom: rem(200);
    p {
      width: 100%;
      height: rem(122);
      line-height: rem(122);
      color: #999;
      font-size: rem(28);
      position: relative;
      // &:last-of-type {
      //   padding-bottom: rem(500);
      // }
      @include ell;
      b {
        // display: block;
        text-align: center;
        @include ell;
      }
    }
    .act {
      color: #ff4848;
      background: #ffffff;
      font-weight: bold;
      // width: rem(140);
      // height: rem(54);
      // border-radius: rem(54/2);
      // color: #fff;
      // position: absolute;
      // top: 0;
      // left: 0;
      // right: 0;
      // bottom: 0;
      // margin: auto;
      // line-height: rem(54);
    }
    .solid {
      position: absolute;
      width: rem(6);
      height: rem(30);
      background: #ff4848;
      border-radius: 0px rem(3) rem(3) 0px;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto;
    }
  }
}

.all-right {
  position: absolute;
  top: rem(104);
  left: rem(160);
  width: rem(590);

  .sort {
    width: rem(558);
    border-radius: rem(16);
    background: #fff;
    height: rem(72);
    margin: rem(16);
    .flex-container {
      flex: 1;
      align-items: center;
      justify-content: center;
      > b {
        color: #666666;
        font-size: rem(28);
      }
      .act {
        color: #ff4848;
      }
    }

    display: flex;
    .image {
      .act {
        width: rem(16);
        height: rem(9);
      }
      img {
        margin: 0 auto;
        width: rem(12);
        height: rem(7);
        display: block;
        &:nth-of-type(1) {
          margin-bottom: rem(4);
        }
      }
    }
  }
}
.product {
  // overflow-y: auto;
  margin-bottom: rem(210);
}
.product-list {
  display: flex;
  // width: rem(558);
  align-items: center;
  // &:last-of-type {
  //   padding-bottom: rem(220);
  // }
  background: #fff;
  // border-radius: rem(16);
  box-sizing: border-box;
  padding: rem(16) rem(24);
  // margin: 0 rem(16);

  .product-img {
    width: rem(194);
    height: rem(194);
    border-radius: rem(8);
    display: block;
  }
  .info {
    // height: rem(178);
    margin-left: rem(16);
    .h1 {
    }
    h1 {
      color: #222222;
      font-weight: 600;
      font-size: rem(28);
      max-height: rem(78);
      overflow: hidden;
      line-height: rem(39);
    }
    p {
      height: rem(40);
      color: #989898;
      font-size: rem(24);
    }
  }
  .price {
    display: flex;
    align-items: center;
    b {
      font-weight: 700;
      font-size: rem(28);
      line-height: rem(30);
      font-family: "price_medium";
      font-weight: 600;
      color: #ff4848;
    }
    span {
      text-decoration-line: line-through;
      color: #ccc;
      font-size: rem(24);
      flex: 1;
      margin-left: rem(16);
      font-family: "price_medium";
      margin-top: rem(4);
    }
    svg {
      width: rem(40);
      height: rem(40);
    }
  }
}

.sku_num {
  color: #222222 !important;
  font-size: rem(30) !important;
  font-weight: bold;
  margin: 0 rem(10);
}

#loading {
  color: #666666;
  text-align: center;
  box-sizing: border-box;
  padding-top: rem(20);
  // padding-bottom: rem(100);
}

.bigClass {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: rem(16);
  .act {
    color: #ff4848;
  }
  .bigClass_li {
    display: flex;
    align-items: center;
    margin-right: rem(8);
    &:first-of-type {
      margin-right: rem(147);
    }
    div {
      font-weight: 400;
      font-size: rem(28);
      line-height: rem(39);
      color: #999999;
    }
    img {
      width: rem(40);
      height: rem(40);
    }
  }
}

.allTop {
  height: rem(208);
  width: 100%;
  background: linear-gradient(180deg, rgba(250, 250, 250, 0) 0%, #fafafa 100%);
}
</style>
